import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cibComposer,
  cibApple,
  cibWindows,
  cibAndroid,
  cibCloudflare,
  cibHuawei,
  cibAmazonAws,
  cibCisco,
  cibDell,
  cibHp

} from "@coreui/icons";
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cifHk,
  cifCn,
  cifJp,
  cifSg,
  cifKr,
  cifDe,
  cifRu,

} from "@coreui/icons";
import {
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilContact,
  cilChatBubble,
  cilCommentBubble,
  cilLockUnlocked,
  cilInfo,
  cilFlightTakeoff,
  cilTransfer,
  cilSmilePlus,
  cilRouter,
  cilSync,
  cilRss,
  cilSim,
  cilDevices,
  cilTag,
  cilTerminal

} from "@coreui/icons";
import { logo } from "./logo";

export const iconsSet = Object.assign(
  {},
  { logo },
  {
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilContact,
    cilChatBubble,
    cilCommentBubble,
    cilLockUnlocked,
    cilInfo,
    cilFlightTakeoff,
    cilTransfer,
    cilSmilePlus,
    cilRouter,
    cilSync,
    cilRss,
    cilSim,
    cilDevices,
    cilTag,
    cilTerminal
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
    cifHk,
    cifCn,
    cifJp,
    cifSg,
    cifKr,
    cifDe,
    cifRu,
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex,
    cibComposer,
    cibApple,
    cibWindows,
    cibAndroid,
    cibCloudflare,
    cibHuawei,
    cibAmazonAws,
    cibCisco,
    cibDell,
    cibHp

  }
);
